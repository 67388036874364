import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { DateRangePicker } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { getLocalTimeZone, today } from "@internationalized/date";
import { useDateFormatter } from "@react-aria/i18n";
import { useSocket } from "../../contexts/SocketContext";
import { sqlDateToJsDate, numberToEmpresa } from "../../tools/tools";
const EstadisticasTable = () => {

    // Obtener la fecha actual
    const todayDate = today(getLocalTimeZone());
    const socket = useSocket();
    // Restar 10 días a la fecha actual para la fecha de inicio
    const startDate = todayDate.subtract({ days: 10 });
    let formatter = useDateFormatter({ dateStyle: "long" });
    const [value, setValue] = useState({
        start: startDate,
        end: todayDate
    });
    const [descargas, setDescargas] = useState([]);


    useEffect(() => {
        socket.off('getDownloads');
        socket.emit('getDownloads', formatter.formatRange(value.start.toDate(getLocalTimeZone()), value.end.toDate(getLocalTimeZone())), localStorage.getItem('token-autocall-ig'));

        socket.on('getDownloadsResponse', (data) => {

            setDescargas(data.downloads);
        });

        return () => {
            if (socket) {
                socket.off('getDownloads');
            }
        };
    }, [socket, value, formatter]);



    const handleChange = (value) => {


        let formatDate = formatter.formatRange(value.start.toDate(getLocalTimeZone()), value.end.toDate(getLocalTimeZone()));

        setValue(value);

        socket.emit('getDownloads', formatDate, localStorage.getItem('token-autocall-ig'));
    }


    return (
        <>
            <div className="flex items-center gap-3">
                <DateRangePicker
                    label="Seleccionar rango de fechas"
                    className="max-w-xs pb-4"
                    value={value}
                    onChange={handleChange}
                    description="El formato de fecha es mm/dd/yyyy"
                />



            </div>

            <Table aria-label="Listado descargas empresas">
                <TableHeader>
                    <TableColumn>EMPRESA</TableColumn>
                    <TableColumn>FECHA</TableColumn>
                    <TableColumn>CANTIDAD</TableColumn>
                </TableHeader>
                <TableBody>
                    {descargas.length > 0 &&
                        descargas.map((descarga, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        {numberToEmpresa(descarga.empresa)}
                                    </TableCell>
                                    <TableCell>
                                        {sqlDateToJsDate(descarga.dia_descarga).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>{descarga.cantidad_descargas} </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </>
    );
};

export default EstadisticasTable;