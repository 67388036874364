import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from '../vistas/Dashbord';
import Login from '../vistas/Login';
import AnadirContacto from '../vistas/AnadirContacto';
import Logs from '../vistas/Logs';
import DescargarContacto from '../vistas/DescargarContcato';
import Configuracion from '../vistas/Configuracion';
import Estadisticas from '../vistas/Estadisticas';
import Usuarios from '../vistas/Usuarios';
import ListadoContactos from '../vistas/ListadoContcatos';
import { useAuth } from '../contexts/AuthContext';
import ConfiguracionLlamadas from '../vistas/ConfiguracionLlamadas';
import ConfiguracionEmails from '../vistas/ConfiguracionEmails';
//confemails
const Rutas = () => {
    const { isLogged } = useAuth();
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={isLogged ? <Navigate to="/" /> : <Login />} />
            <Route path="/anadircontacto" element={<AnadirContacto />} />
            <Route path="/descargarcontacto" element={<DescargarContacto />} />
            <Route path="/listadocontactos" element={<ListadoContactos />} />
            <Route path="/configuracion" element={<Configuracion />} />
            <Route path="/estadisticas" element={<Estadisticas />} />
            <Route path="/usuarios" element={<Usuarios />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/confllamadas" element={<ConfiguracionLlamadas/>} />
            <Route path="/confemails" element={<ConfiguracionEmails/>} />
            <Route path="/logs" element={<Logs/>} />

        </Routes>
    )
}
export default Rutas;